<template>
  <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="New"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew"
              />
              <!-- <Button
                label="Delete"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="!selectedProducts || !selectedProducts.length"
              /> -->
              <!-- &nbsp; -->
              <!-- <Button
                icon="pi pi-sync"
                class="p-button-info mr-2"
                @click="get_list"
              /> -->
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload
              mode="basic"
              accept="application/pdf,image/jpeg,image/png"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            />
            <Button
              label="Export"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            /> -->
          </template>
        </Toolbar>
       
        <DataTable
          
          :loading="loading"
          ref="dt"
          :lazy="true"
          :totalRecords="totalRecords"
          :paginator="true"
          :value="products"
          v-model:selection="selectedProducts"
          :dataKey="columns[0]"
          :rows="limit"
          :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, totalRecords]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll"
          style="font-size: 12px"
          :rowHover="true" showGridlines
        ><template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
            >
              <h5 class="m-0"><b> JEE / NEET Upload Test</b></h5>
              <Button icon="pi pi-refresh" @click="get_list()" />
              <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span> -->
            </div>
          </template>

          <!-- <Column v-for="(col,index) in columns" :field="col" :header="col.toUpperCase()" :key="index"></Column> -->
          <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->

          <Column header="Status" style="max-width: 6rem;text-align: center;" :field="columns[0]">
            <template #body="{ data }">
               <span v-if="data.status=='Active' ||data.status=='active'"><badge :value=data.status severity="success"></badge></span>
            <span v-else-if="data.status=='InActive' ||data.status=='In Active'"><badge :value=data.status severity="warning"></badge></span>
            <span v-else-if="data.status=='Delete' ||data.status=='Deleted'"><badge :value=data.status severity="danger"></badge></span>
            <span v-else>-</span>
            </template>
          </Column>

          <Column header="Sr No" style="min-width: 5rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="page_no==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
            </template>
          </Column>


          <Column header="Year" style="max-width: 5rem;text-align: right;" :field="columns[1]">
            <template #body="{ data }">
              {{ data.year }}
            </template>
          </Column>
          <Column
            header="Test Number"
            style="max-width: 15rem"
            :field="columns[6]"
          >
            <template #body="{ data }">
              {{ data.tesT_NUMBER }}
            </template>
          </Column>
          <Column
            header="Test Date"
            style="max-width: 15rem"
            :field="columns[2]"
          >
            <template #body="{ data }">
              {{ data.tesT_DATE }}
            </template>
          </Column>

          <Column
            header="Math."
            style="max-width: 5rem"
            :field="columns[2]"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.mathematics == '' ||
                  data.mathematics == null ||
                  data.mathematics == undefined
                "
                ><Button
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger"
              /></span>
              <span v-else
                ><Button
                  icon="pi pi-check"
                  class="p-button-rounded p-button-success"
              /></span>
            </template>
          </Column>
          <Column
            header="Math Sol."
            style="max-width: 5rem"
            :field="columns[2]"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.mathS_SOLUTIONS == '' ||
                  data.mathS_SOLUTIONS == null ||
                  data.mathS_SOLUTIONS == undefined
                "
                ><Button
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger"
              /></span>
              <span v-else
                ><Button
                  icon="pi pi-check"
                  class="p-button-rounded p-button-success"
              /></span>
            </template>
          </Column>
          <Column header="Physics" style="max-width: 5rem" :field="columns[2]">
            <template #body="{ data }">
              <span
                v-if="
                  data.physics == '' ||
                  data.physics == null ||
                  data.physics == undefined
                "
                ><Button
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger"
              /></span>
              <span v-else
                ><Button
                  icon="pi pi-check"
                  class="p-button-rounded p-button-success"
              /></span>
            </template>
          </Column>
          <Column
            header="Physics Sol."
            style="max-width: 5rem"
            :field="columns[2]"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.p_SOLUTIONS == '' ||
                  data.p_SOLUTIONS == null ||
                  data.p_SOLUTIONS == undefined
                "
                ><Button
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger"
              /></span>
              <span v-else
                ><Button
                  icon="pi pi-check"
                  class="p-button-rounded p-button-success"
              /></span>
            </template>
          </Column>
          <Column
            header="Chemistry"
            style="max-width: 5rem"
            :field="columns[2]"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.chemistry == '' ||
                  data.chemistry == null ||
                  data.chemistry == undefined
                "
                ><Button
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger"
              /></span>
              <span v-else
                ><Button
                  icon="pi pi-check"
                  class="p-button-rounded p-button-success"
              /></span>
            </template>
          </Column>
          <Column
            header="Chemistry Sol."
            style="max-width: 5rem"
            :field="columns[2]"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.c_SOLUTIONS == '' ||
                  data.c_SOLUTIONS == null ||
                  data.c_SOLUTIONS == undefined
                "
                ><Button
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger"
              /></span>
              <span v-else
                ><Button
                  icon="pi pi-check"
                  class="p-button-rounded p-button-success"
              /></span>
            </template>
          </Column>
          <Column header="Botony" style="max-width: 5rem" :field="columns[2]">
            <template #body="{ data }">
              <span
                v-if="
                  data.botony == '' ||
                  data.botony == null ||
                  data.botony == undefined
                "
                ><Button
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger"
              /></span>
              <span v-else
                ><Button
                  icon="pi pi-check"
                  class="p-button-rounded p-button-success"
              /></span>
            </template>
          </Column>
          <Column
            header="Botony Sol."
            style="max-width: 5rem"
            :field="columns[2]"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.b_SOLUTIONS == '' ||
                  data.b_SOLUTIONS == null ||
                  data.b_SOLUTIONS == undefined
                "
                ><Button
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger"
              /></span>
              <span v-else
                ><Button
                  icon="pi pi-check"
                  class="p-button-rounded p-button-success"
              /></span>
            </template>
          </Column>
          <Column header="Zoology" style="max-width: 5rem" :field="columns[2]">
            <template #body="{ data }">
              <span
                v-if="
                  data.zoology == '' ||
                  data.zoology == null ||
                  data.zoology == undefined
                "
                ><Button
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger"
              /></span>
              <span v-else
                ><Button
                  icon="pi pi-check"
                  class="p-button-rounded p-button-success"
              /></span>
            </template>
          </Column>
          <Column
            header="Zoology Sol."
            style="max-width: 5rem"
            :field="columns[2]"
          >
            <template #body="{ data }">
              <span
                v-if="
                  data.z_SOLUTIONS == '' ||
                  data.z_SOLUTIONS == null ||
                  data.z_SOLUTIONS == undefined
                "
                ><Button
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger"
              /></span>
              <span v-else
                ><Button
                  icon="pi pi-check"
                  class="p-button-rounded p-button-success"
              /></span>
            </template>
          </Column>

          <Column headerStyle="min-width:10rem;" header="Edit/Delete">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <Sidebar
          v-model:visible="productDialog"
          :baseZIndex="1000"
          position="full"
        >
          <div class="col-12">
            <div class="card">
              <h5>JEE / NEET Test</h5>
              <hr />
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-2">
                  <label for="status">Status</label>
                  <Dropdown
                    id="status"
                    v-model="selectstatus"
                    :options="dropdownItems"
                    :value="dropdownItems.value"
                    optionLabel="name"
                    placeholder="Select One"
                    required="true"
                  ></Dropdown>
                </div>
                <div class="field col-12 md:col-2">
                  <label for="status">Year</label>
                  <Dropdown
                    id="status"
                    v-model="submissionstatus"
                    :options="submissionItems"
                    :value="submissionItems.value"
                    optionLabel="name"
                    placeholder="Select One"
                    required="true"
                  ></Dropdown>
                </div>
                <div class="field col-12 md:col-2">
                  <label for="status">Test Date</label>
                  <Calendar
                    style="width: 100%"
                    v-model="product.tesT_DATE"
                    :showIcon="true"
                    dateFormat="d-MM-yy"
                    hourFormat="12"
                    :numberOfMonths="2"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="schedule">Test Number</label>
                  <InputText
                    type="text"
                    id="inputtext"
                    required="true"
                    autofocus
                    v-model="product.tesT_NUMBER"
                  />
                </div>
                <div class="field col-12 md:col-2"></div>
                <div class="field col-12 md:col-2"></div>

                <div class="field col-12 md:col-4">
                  <label for="schedule">Mathamatics Topic</label>

                  <InputText
                    type="text"
                    id="inputtext"
                    required="true"
                    autofocus
                    v-model="product.m_TOPIC"
                  />
                </div>
                <div class="field col-12 md:col-4">
                  <label for="schedule">Mathamatics(Link)</label>

                  <InputText
                    type="text"
                    id="inputtext"
                    required="true"
                    autofocus
                    v-model="product.mathematics"
                  />
                </div>
                <div class="field col-12 md:col-4">
                  <label for="schedule">Mathamatics Solutions</label>

                  <FileUpload
                  mode="basic"
                  id="file_attachmentMS"
                  ref="file_attachmentMS"
                  @change="handleFileUpload('MS')"
                  name="demo[]"
                  accept="application/pdf,image/jpeg,image/png"
                  :maxFileSize="1000000"
                />
                </div>

                <!-- ------------------------------------------------------------- -->
                <div class="field col-12 md:col-4">
                  <label for="schedule">Physics Topic</label>

                  <InputText
                    type="text"
                    id="inputtext"
                    required="true"
                    autofocus
                    v-model="product.p_TOPIC"
                  />
                </div>
                <div class="field col-12 md:col-4">
                  <label for="schedule">Physics(Link)</label>

                  <InputText
                    type="text"
                    id="inputtext"
                    required="true"
                    autofocus
                    v-model="product.physics"
                  />
                </div>
                <div class="field col-12 md:col-4">
                  <label for="schedule">Physics Solutions</label>

                  <FileUpload
                  mode="basic"
                  id="file_attachmentPS"
                  ref="file_attachmentPS"
                  @change="handleFileUpload('PS')"
                  name="demo[]"
                  accept="application/pdf,image/jpeg,image/png"
                  :maxFileSize="1000000"
                />
                </div>

                <!-- ------------------------------------------------------------- -->
                <div class="field col-12 md:col-4">
                  <label for="schedule">Chemistry Topic</label>

                  <InputText
                    type="text"
                    id="inputtext"
                    required="true"
                    autofocus
                    v-model="product.c_TOPIC"
                  />
                </div>
                <div class="field col-12 md:col-4">
                  <label for="schedule">Chemistry(Link)</label>

                  <InputText
                    type="text"
                    id="inputtext"
                    required="true"
                    autofocus
                    v-model="product.chemistry"
                  />
                </div>
                <div class="field col-12 md:col-4">
                  <label for="schedule">Chemistry Solutions</label>

                  <FileUpload
                  mode="basic"
                  id="file_attachmentCS"
                  ref="file_attachmentCS"
                  @change="handleFileUpload('CS')"
                  name="demo[]"
                  accept="application/pdf,image/jpeg,image/png"
                  :maxFileSize="1000000"
                />
                </div>

                <!-- ------------------------------------------------------------- -->
                <div class="field col-12 md:col-4">
                  <label for="schedule">Botony Topic</label>

                  <InputText
                    type="text"
                    id="inputtext"
                    required="true"
                    autofocus
                    v-model="product.b_TOPIC"
                  />
                </div>
                <div class="field col-12 md:col-4">
                  <label for="schedule">Botony(Link)</label>

                  <InputText
                    type="text"
                    id="inputtext"
                    required="true"
                    autofocus
                    v-model="product.botony"
                  />
                </div>
                <div class="field col-12 md:col-4">
                  <label for="schedule">Botony Solutions</label>

                   <FileUpload
                  mode="basic"
                  id="file_attachmentBS"
                  ref="file_attachmentBS"
                  @change="handleFileUpload('BS')"
                  name="demo[]"
                  accept="application/pdf,image/jpeg,image/png"
                  :maxFileSize="1000000"
                />
                </div>

                <!-- ------------------------------------------------------------- -->
                <div class="field col-12 md:col-4">
                  <label for="schedule">Zoology Topic</label>

                  <InputText
                    type="text"
                    id="inputtext"
                    required="true"
                    autofocus
                    v-model="product.z_TOPIC"
                  />
                </div>
                <div class="field col-12 md:col-4">
                  <label for="schedule">Zoology(Link)</label>

                  <InputText
                    type="text"
                    id="inputtext"
                    required="true"
                    autofocus
                    v-model="product.zoology"
                  />
                </div>
                <div class="field col-12 md:col-4">
                  <label for="schedule">Zoology Solutions</label>

                  <FileUpload
                  mode="basic"
                  id="file_attachmentZS"
                  ref="file_attachmentZS"
                  @change="handleFileUpload('ZS')"
                  name="demo[]"
                  accept="application/pdf,image/jpeg,image/png"
                  :maxFileSize="1000000"
                />
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="card">
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-3">
                  <Button
                    v-if="product.mjtpjN_ID"
                    label="UPDATE"
                    icon="pi pi-check"
                    class="p-button-success mr-1 mb-1"
                    @click="Addedituploadtest"
                  />
                  <Button
                    v-else
                    label="ADD"
                    icon="pi pi-check"
                    class="p-button-success mr-1 mb-1"
                    @click="Addedituploadtest"
                  />
                </div>
                <div class="field col-12 md:col-3">
                  <Button
                    label="Cancel"
                    icon="pi pi-times"
                    class="p-button-danger mr-1 mb-1"
                    @click="productDialog = false"
                  />
                </div>
              </div>
            </div>
          </div>
        </Sidebar>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete <b>{{ product.mjtpjN_ID }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deletetest"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete the selected products?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      page_no:1,
      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,
      file_attachmentMS: "",
      file_attachmentPS: "",
      file_attachmentCS: "",
      file_attachmentBS: "",
      file_attachmentZS: "",

      dropdownItems: [
        { name: "Active", value: "Active" },
        { name: "Inactive", value: "Inactive" },
        { name: "Deleted", value: "Deleted" },
      ],
      submissionstatus: { name: "", value: "" },
      submissionItems: [],
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      selectstatus: { name: "", value: "" },

      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus",
        "Course",
      ],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    this.get_submission_dropdown();
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = localStorage.getItem("id");

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
    //a simple date formatting function
    dateFormat(inputDate, info) {},
    handleFileUpload(data) {
      alert(data)
      if(data=='MS'){this.file_attachmentMS = this.$refs.file_attachmentMS.files[0];}

      if(data=='PS'){this.file_attachmentPS = this.$refs.file_attachmentPS.files[0];}

      if(data=='CS'){this.file_attachmentCS = this.$refs.file_attachmentCS.files[0];}

      if(data=='BS'){this.file_attachmentBS = this.$refs.file_attachmentBS.files[0];}

      if(data=='ZS'){this.file_attachmentZS = this.$refs.file_attachmentZS.files[0];}
      

      //alert(this.$refs.file_attachment.files[0].name);
    },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_submission_dropdown: function () {
      var data = {
        //  "TEACHER_ID":parseInt(localStorage.getItem("id"))
        Limit: 100,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.yearlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);

        var info = response.data;
        if (info) {
          this.submissionItems = [];
          for (var i = 0; i < info.length; i++) {
            this.submissionItems.push({
              name: info[i].exaM_YEAR,
              value: info[i].enrollmenT_YEAR,
            });
          }
        }
      });
    },
    get_count: function () {
      var data = {
        count: true,
      };
      this.loading = true;
      var promise = apis.testpapersmaster(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.totalRecords = response.data;
        //alert(this.totalRecords);
      });

      console.log(data);
    },
    get_list: function () {
      var data = {
        Limit: this.limit,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.testpapersmaster(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.products = response.data;
        console.log(this.products[0]);
        if (this.columns.length == 0) {
          if (this.products.length > 0) {
            this.columns = Object.keys(this.products[0]);
          }
        }
      });

      console.log(data);
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    async Addedituploadtest() {
      this.submitted = true;
      if(!this.selectstatus.value)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Status",
            life: 3000,
          });
          return false;
    }
    if(!this.submissionstatus.value)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Year",
            life: 3000,
          });
          return false;
    }
    if(!this.product.tesT_DATE)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Test Date",
            life: 3000,
          });
          return false;
    }
    if(!this.product.tesT_NUMBER)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Test Number",
            life: 3000,
          });
          return false;
    }

     if(!this.product.m_TOPIC)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Mathamatics Topic",
            life: 3000,
          });
          return false;
    }

    if(!this.product.mathematics)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Mathamatics",
            life: 3000,
          });
          return false;
    }

    if(!this.product.p_TOPIC)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Physics Topic",
            life: 3000,
          });
          return false;
    }

    if(!this.product.physics)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Physics link",
            life: 3000,
          });
          return false;
    }

    if(!this.product.c_TOPIC)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Chemistry Topic",
            life: 3000,
          });
          return false;
    }

    if(!this.product.chemistry)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Chemistry link",
            life: 3000,
          });
          return false;
    }

    if(!this.product.b_TOPIC)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Botony Topic",
            life: 3000,
          });
          return false;
    }

    if(!this.product.botony)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Botony link",
            life: 3000,
          });
          return false;
    }


    if(!this.product.z_TOPIC)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Zoology Topic",
            life: 3000,
          });
          return false;
    }

    if(!this.product.zoology)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Zoology link",
            life: 3000,
          });
          return false;
    }















      var from = new Date(this.product.tesT_DATE);
        var T_date =
          from.toLocaleDateString("en-US", { year: "numeric" }) +
          "-" +
          from.toLocaleDateString("en-US", { month: "2-digit" }) +
          "-" +
          from.toLocaleDateString("en-US", { day: "2-digit" });
      
      

      //edit
      if (this.product.mjtpjN_ID) {
        if(this.submissionstatus.value==""){alert("error date");return false;}

             var d = new Date();
        var MS_file="";
        if(this.file_attachmentMS.type==undefined){MS_file=this.product.mathS_SOLUTIONS}else{MS_file=d.getTime() + "_" + d.getMilliseconds() + "_" + this.file_attachmentMS.name;}
        var PS_file="";
        if(this.file_attachmentPS.type==undefined){PS_file=this.product.p_SOLUTIONS}else{PS_file=d.getTime() + "_" + d.getMilliseconds() + "_" + this.file_attachmentPS.name;}
        var CS_file="";
        if(this.file_attachmentCS.type==undefined){CS_file=this.product.c_SOLUTIONS}else{CS_file=d.getTime() + "_" + d.getMilliseconds() + "_" + this.file_attachmentCS.name;}
        var BS_file="";
        if(this.file_attachmentBS.type==undefined){BS_file=this.product.b_SOLUTIONS}else{BS_file=d.getTime() + "_" + d.getMilliseconds() + "_" + this.file_attachmentBS.name;}
        var ZS_file="";
        if(this.file_attachmentZS.type==undefined){ZS_file=this.product.z_SOLUTIONS}else{ZS_file=d.getTime() + "_" + d.getMilliseconds() + "_" + this.file_attachmentZS.name;}

        

        var data = {
          "mjtpjN_ID":this.product.mjtpjN_ID,
    "tesT_DATE": T_date,
    "mathematics": this.product.mathematics,
    "mathS_SOLUTIONS": MS_file,
    "physics": this.product.physics,
    "p_SOLUTIONS": PS_file,
    "chemistry": this.product.chemistry,
    "c_SOLUTIONS": CS_file,
    "botony": this.product.botony,
    "b_SOLUTIONS": BS_file,
    "zoology": this.product.zoology,
    "z_SOLUTIONS": ZS_file,
    "status": this.selectstatus.value,
    "updated_BY": this.id,
    "tesT_NUMBER": this.product.tesT_NUMBER,
    "year": this.submissionstatus.value,
    "m_TOPIC": this.product.m_TOPIC,
    "p_TOPIC": this.product.p_TOPIC,
    "c_TOPIC": this.product.c_TOPIC,
    "b_TOPIC": this.product.b_TOPIC,
    "z_TOPIC": this.product.z_TOPIC,

         
        };
       
        var promise = apis.testedit(data);
        promise
          .then((responseapi) => {
            // this.$swal(responseapi.data);
            // this.isLoadingModel = false;
            // this.productDialog = false;
            // this.get_list();
            // this.get_count();
             if (responseapi.status == 200) {
              if (this.file_attachmentMS.type) 
              {
                var file_data1={
                  filePath:"Testmaster/" + MS_file,
                  contentType: this.file_attachmentMS.type
                }
                var promise1 = apis.uploadFile(file_data1);
                promise1
                  .then((response) => {
                    axios
                      .put(response.data.url, this.file_attachmentMS, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": this.file_attachmentMS.type,
                        },
                      })
                      .then((response) => {
                      })
                      .catch((error) => {
                        this.isLoadingModel = false;
                        this.$swal("error to upload file");
                      });
                  });

              }
              if (this.file_attachmentPS.type) 
              {
                var file_data2={
                  filePath:"Testmaster/" + PS_file,
                  contentType: this.file_attachmentPS.type
                }
                var promise2 = apis.uploadFile(file_data2);
                promise2
                  .then((response) => {
                    axios
                      .put(response.data.url, this.file_attachmentPS, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": this.file_attachmentPS.type,
                        },
                      })
                      .then((response) => {
                      })
                      .catch((error) => {
                        this.isLoadingModel = false;
                        this.$swal("error to upload file");
                      });
                  });

              }
              if (this.file_attachmentCS.type) 
              {
                var file_data3={
                  filePath:"Testmaster/" + CS_file,
                  contentType: this.file_attachmentCS.type
                }
                var promise3 = apis.uploadFile(file_data3);
                promise3
                  .then((response) => {
                    axios
                      .put(response.data.url, this.file_attachmentCS, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": this.file_attachmentCS.type,
                        },
                      })
                      .then((response) => {
                      })
                      .catch((error) => {
                        this.isLoadingModel = false;
                        this.$swal("error to upload file");
                      });
                  });

              }
              if (this.file_attachmentBS.type) 
              {
                var file_data4={
                  filePath:"Testmaster/" + BS_file,
                  contentType: this.file_attachmentBS.type
                }
                var promise4 = apis.uploadFile(file_data4);
                promise4
                  .then((response) => {
                    axios
                      .put(response.data.url, this.file_attachmentBS, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": this.file_attachmentBS.type,
                        },
                      })
                      .then((response) => {
                      })
                      .catch((error) => {
                        this.isLoadingModel = false;
                        this.$swal("error to upload file");
                      });
                  });

              }
              if (this.file_attachmentZS.type) 
              {
                var file_data5={
                  filePath:"Testmaster/" + ZS_file,
                  contentType: this.file_attachmentZS.type
                }
                var promise5 = apis.uploadFile(file_data5);
                promise5
                  .then((response) => {
                    axios
                      .put(response.data.url, this.file_attachmentZS, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": this.file_attachmentZS.type,
                        },
                      })
                      .then((response) => {
                      })
                      .catch((error) => {
                        this.isLoadingModel = false;
                        this.$swal("error to upload file");
                      });
                  });

              }

             }
              this.get_list();
            this.get_count();
          })
          .catch((error) => {
            console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }
      //add-----------------------------------------------------------------------------------------------------------------
      else {

         if(this.submissionstatus.value==""){alert("error date");return false;}

        var d2 = new Date();
        var MS_file2="";
        if(this.file_attachmentMS.type==undefined){MS_file2=null}else{MS_file2=d2.getTime() + "_" + d2.getMilliseconds() + "_" + this.file_attachmentMS.name;}
        var PS_file2="";
        if(this.file_attachmentPS.type==undefined){PS_file2=null}else{PS_file2=d2.getTime() + "_" + d2.getMilliseconds() + "_" + this.file_attachmentPS.name;}
        var CS_file2="";
        if(this.file_attachmentCS.type==undefined){CS_file2=null}else{CS_file2=d2.getTime() + "_" + d2.getMilliseconds() + "_" + this.file_attachmentCS.name;}
        var BS_file2="";
        if(this.file_attachmentBS.type==undefined){BS_file2=null}else{BS_file2=d2.getTime() + "_" + d2.getMilliseconds() + "_" + this.file_attachmentBS.name;}
        var ZS_file2="";
        if(this.file_attachmentZS.type==undefined){ZS_file2=null}else{ZS_file2=d2.getTime() + "_" + d2.getMilliseconds() + "_" + this.file_attachmentZS.name;}




        var data1 = {
          
    "mathematics": this.product.mathematics,
    "mathS_SOLUTIONS": MS_file2,
    "physics": this.product.physics,
    "p_SOLUTIONS": PS_file2,
    "chemistry": this.product.chemistry,
    "c_SOLUTIONS": CS_file2,
    "botony": this.product.botony,
    "b_SOLUTIONS": BS_file2,
    "zoology": this.product.zoology,
    "z_SOLUTIONS": ZS_file2,


  "tesT_DATE": T_date,
  "status": this.selectstatus.value,
  "tesT_NUMBER": this.product.tesT_NUMBER,
  "year": this.submissionstatus.value,
  "m_TOPIC": this.product.m_TOPIC,
    "p_TOPIC": this.product.p_TOPIC,
    "c_TOPIC": this.product.c_TOPIC,
    "b_TOPIC": this.product.b_TOPIC,
    "z_TOPIC": this.product.z_TOPIC,

     "createD_ON": new Date(),
    "createD_BY": this.id,
    "updateD_ON": new Date(),
    "updateD_BY": this.id   
       
       
       };
       
        var promises = apis.testadd(data1);
        promises
          .then((responseapi) => {
            this.isLoadingModel = false;
            this.productDialog = false;
            this.$swal(responseapi.data);
           
            if (responseapi.status == 200) {
              if (this.file_attachmentMS.type) 
              {
                var file_data1={
                  filePath:"Testmaster/" + MS_file2,
                  contentType: this.file_attachmentMS.type
                }
                var promise1 = apis.uploadFile(file_data1);
                promise1
                  .then((response) => {
                    axios
                      .put(response.data.url, this.file_attachmentMS, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": this.file_attachmentMS.type,
                        },
                      })
                      .then((response) => {
                      })
                      .catch((error) => {
                        this.isLoadingModel = false;
                        this.$swal("error to upload file");
                      });
                  });

              }
              if (this.file_attachmentPS.type) 
              {
                var file_data2={
                  filePath:"Testmaster/" + PS_file2,
                  contentType: this.file_attachmentPS.type
                }
                var promise2 = apis.uploadFile(file_data2);
                promise2
                  .then((response) => {
                    axios
                      .put(response.data.url, this.file_attachmentPS, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": this.file_attachmentPS.type,
                        },
                      })
                      .then((response) => {
                      })
                      .catch((error) => {
                        this.isLoadingModel = false;
                        this.$swal("error to upload file");
                      });
                  });

              }
              if (this.file_attachmentCS.type) 
              {
                var file_data3={
                  filePath:"Testmaster/" + CS_file2,
                  contentType: this.file_attachmentCS.type
                }
                var promise3 = apis.uploadFile(file_data3);
                promise3
                  .then((response) => {
                    axios
                      .put(response.data.url, this.file_attachmentCS, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": this.file_attachmentCS.type,
                        },
                      })
                      .then((response) => {
                      })
                      .catch((error) => {
                        this.isLoadingModel = false;
                        this.$swal("error to upload file");
                      });
                  });

              }
              if (this.file_attachmentBS.type) 
              {
                var file_data4={
                  filePath:"Testmaster/" + BS_file2,
                  contentType: this.file_attachmentBS.type
                }
                var promise4 = apis.uploadFile(file_data4);
                promise4
                  .then((response) => {
                    axios
                      .put(response.data.url, this.file_attachmentBS, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": this.file_attachmentBS.type,
                        },
                      })
                      .then((response) => {
                      })
                      .catch((error) => {
                        this.isLoadingModel = false;
                        this.$swal("error to upload file");
                      });
                  });

              }
              if (this.file_attachmentZS.type) 
              {
                var file_data5={
                  filePath:"Testmaster/" + ZS_file2,
                  contentType: this.file_attachmentZS.type
                }
                var promise5 = apis.uploadFile(file_data5);
                promise5
                  .then((response) => {
                    axios
                      .put(response.data.url, this.file_attachmentZS, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": this.file_attachmentZS.type,
                        },
                      })
                      .then((response) => {
                      })
                      .catch((error) => {
                        this.isLoadingModel = false;
                        this.$swal("error to upload file");
                      });
                  });

              }

             }
              this.get_list();
            this.get_count();


          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }

      this.selectstatus = { name: "", value: "" };
      this.submissionstatus = { name: "", value: "" };
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },
    editProduct(product) {
      this.file_attachmentMS = "";
      this.file_attachmentPS = "";
      this.file_attachmentCS = "";
      this.file_attachmentBS = "";
      this.file_attachmentZS = "";

      this.product = { ...product };

      this.selectstatus = { name: "", value: "" };
      this.selectstatus.value = this.product.status;
      this.selectstatus.name = this.product.status;

      this.submissionstatus = { name: "", value: "" };

      

      // this.selectstatus2.value = this.product.status;
      // this.selectstatus2.name = this.product.status;

      // this.file_attachment = this.product.image;
      this.productDialog = true;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deletetest() {
      if (this.product.mjtpjN_ID) {
        var data = {
          mjtpjN_ID: this.product.mjtpjN_ID,
        };
        this.isLoadingModel = true;
        var promise = apis.testdelete(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.status == 200) {
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Video");
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
